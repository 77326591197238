import axios from "../axios-config";

class ContractService {
  getContractById(id) {
    return axios.get("contract/get/"+id);
  }

  getPendingByUserId(userId, filters = {}) {
    return axios.get("contract/getPendingByUser/"+userId, {
      params: {
        ...filters
      }
    });
  }

  getContractsOfBatch(batchId) {
    return axios.get("contract/getByBatch/"+batchId);
  }

  getAllPending(filters = {}) {
    return axios.get("contract/getAllPending", {
      params: {
        ...filters
      }
    });
  }
  getContractByNumber(batchNumber){
    const params ={
      value : batchNumber
    }
    return axios.get("contract/getByNumber",{params});
  }
  visaContract(contractId, visa, typification, position={}) {
    console.log("typification", typification)
    return axios.put("contract/visa/"+contractId, {
      visa,
      tipificacion: typification,
      ...position
    });
  }
  editVisaContract(contractId, data) {
    return axios.put(`/contract/${contractId}/visa/changeState`, data);
  }
  getVisaChangeTypes() {
    return axios.get("contract/visaChangeTypes");
  }
  getContractStates(contractId) {
    return axios.get(`/contract/${contractId}/states`);
  }
  getContractForTicket(id){
    return axios.get(`/contract/forTicket/${id}`);
  }
  getRendicionStates(){
    return axios.get("contract/rendicionStates");
  }
}

export default new ContractService();
